import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["href", "aria-describedby"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "resultTags" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.result.url,
    class: "resultItem",
    "aria-describedby": _ctx.result.id
  }, [
    (_ctx.result.title)
      ? (_openBlock(), _createElementBlock("h3", {
          key: 0,
          class: "h5",
          id: _ctx.result.id
        }, _toDisplayString(_ctx.result.title), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("p", null, [
      _createElementVNode("span", { innerHTML: _ctx.highlights }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("ul", _hoisted_4, [
      _createElementVNode("li", null, _toDisplayString(_ctx.result.url), 1)
    ])
  ], 8, _hoisted_1))
}