import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", null, [
    _createTextVNode(_toDisplayString(_ctx.$t("ResultsIndicatorShowing")) + " ", 1),
    _createElementVNode("strong", null, _toDisplayString(_ctx.first) + " - " + _toDisplayString(_ctx.last), 1),
    _createTextVNode(" " + _toDisplayString(_ctx.$t("ResultsIndicatorOf")) + " ", 1),
    _createElementVNode("strong", null, _toDisplayString(_ctx.total), 1),
    _createTextVNode(" " + _toDisplayString(_ctx.$t("ResultsIndicatorFor")) + " ", 1),
    _createElementVNode("strong", null, _toDisplayString(_ctx.term), 1)
  ]))
}