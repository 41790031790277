export const RemoveTermFromSessionStorage = (itemName, term) => {
  let recentSearchTerms = [];
  const terms = sessionStorage.getItem(itemName);
  if (terms != null) {
    if (terms.includes("|")) {
      const termList = terms.split("|");
      for (let i = 0; i < termList.length; i++) {
        if (termList[i] == term) {
          termList.splice(i, 1);
          sessionStorage.clear();
          sessionStorage.setItem(itemName, termList.join("|"));
          recentSearchTerms = termList;
          return recentSearchTerms;
        }
      }
    } else {
      sessionStorage.clear();
      return recentSearchTerms;
    }
  }
  return recentSearchTerms;
};

export const AddTermToSessionsStorage = (itemName, searchTerm) => {
  const recentTerms = sessionStorage.getItem(itemName);
  let itemExistsInStorage = false;
  if (recentTerms != null) {
    const splitPages = recentTerms.split("|");
    let recentSearchTerms = splitPages;
    for (let i = 0; i < splitPages.length; i++) {
      if (splitPages[i] == searchTerm) {
        itemExistsInStorage = true;
      }
    }
    if (!itemExistsInStorage) {
      const splitObjs = recentTerms.split("|");
      splitObjs.push(searchTerm);
      const joinedList = splitObjs.join("|");
      sessionStorage.clear();
      sessionStorage.setItem(itemName, joinedList);
      recentSearchTerms = splitObjs;
      return recentSearchTerms;
    }
  } else {
    const recentSearchTerms = [];
    sessionStorage.setItem(itemName, searchTerm);
    return recentSearchTerms;
  }
};

export default {
  AddTermToSessionsStorage,
  RemoveTermFromSessionStorage,
};
