<template>
  <div id="app">
    <section class="searchBanner">
      <h2 class="searchOverlayTitle">Search</h2>
      <RenderResources
        :indexName="index"
        :endPoint="apiendpoint"
        :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
        :baseUri="baseUri"
        :filteryear="filteryear"
        :parentid="parentid"
        :page="1"
        :filtersRequired="1"
      >
        <template v-slot:testSlot="{ loading, error }">
          <div class="formContainer">
            <div class="error" v-if="!loading && error">
              {{ $t("LoadingError") }}
            </div>
            <div class="loading" v-if="loading && !error">
              <p>{{ $t("LoadingFilters") }}</p>
            </div>
            <form
              @submit.prevent="handleSearchClicked"
              v-if="!loading && !error"
              autocomplete="off"
            >
              <div class="searchInput">
                <label for="siteSearchInput" class="srOnly">
                  {{ $t("SearchLabel") }}
                </label>
                <input
                  id="siteSearchInput"
                  @keydown.enter.prevent="handleSearchClicked"
                  @keyup="handleKeywordInputChanged"
                  type="search"
                  class="sortingSearchInput"
                  v-model="keyword"
                />
                <div
                  class="searchAutocomplete"
                  v-if="suggestedSearchTerms.length > 0"
                >
                  <ul>
                    <li v-for="term in suggestedSearchTerms" :key="term">
                      <button
                        v-on:click="handleSelectSearchTerm(term)"
                        :aria-label="'Search for ' + term"
                      >
                        {{ term }}
                      </button>
                    </li>
                  </ul>
                </div>
                <button
                  class="clearButton"
                  v-if="keyword.length > 0"
                  @click="handleClearClicked"
                  aria-label="Clear keyword"
                >
                  <svg
                    id="close"
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    preserveAspectRatio="xMinYMid"
                    aria-hidden="true"
                    role="img"
                    fill="#1A1347"
                  >
                    <g>
                      <path
                        d="M315.25 20.25V31H326v1.5h-10.75v10.75h-1.5V32.5H303V31h10.75V20.25h1.5z"
                        transform="translate(-305 -79) translate(0 56) rotate(45 314.5 31.75)"
                      />
                    </g>
                  </svg>
                </button>
                <button
                  type="submit"
                  class="searchButton button"
                  @click="handleSearchClicked"
                  aria-label="Submit search"
                >
                  Search
                </button>
              </div>
            </form>
            <recent-terms
              v-if="searchTerm.length == 0"
              @updateSearchTerm="handleSelectSearchTerm"
            ></recent-terms>
          </div>
        </template>
      </RenderResources>
    </section>
    <section class="searchResults">
      <RenderResources
        :parentid="parentid"
        :indexName="index"
        :endPoint="apiendpoint"
        :tags="selectedTags"
        :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
        :years="selectedYears"
        :page="currentPage"
        :baseUri="baseUri"
        :keyword="searchTerm"
        :pagesize="pagesize"
        @change="resourcesChanged"
        v-if="searchTerm"
      >
        <template v-slot:testSlot="{ result, loading, error }">
          <div class="inner">
            <div class="resultsTop">
              <div class="error" v-if="!loading && error">
                {{ $t("LoadingResourceError") }}
              </div>
              <div class="loading" v-if="loading && !error">
                <p>{{ $t("LoadingResults") }}</p>
              </div>
              <ResultsIndicator
                v-if="result !== undefined && result.count > 0"
                :first="firstPage"
                :term="searchTerm"
                :last="lastPage(result.count)"
                :total="result.count"
              ></ResultsIndicator>
            </div>
            <div
              class="resultsContainer"
              v-if="
                !loading &&
                !error &&
                result.documents !== undefined &&
                result.documents.length > 0
              "
              :class="{ hasPagination: totalPages > 1 }"
            >
              <Result
                v-for="responseValueItem in result.documents"
                :key="responseValueItem.id"
                :result="responseValueItem"
              ></Result>
            </div>

            <div
              class="noResults"
              v-if="
                !loading &&
                !error &&
                (result === undefined || result.count === 0)
              "
            >
              <p>{{ $t("NoResults") }}</p>
            </div>

            <nav
              class="pagination"
              v-if="result !== undefined && result.count > 0 && totalPages > 1"
              role="navigation"
              aria-label="Pagination Navigation"
            >
              <pagination
                v-model="currentPage"
                :records="result.count"
                :per-page="pagesize"
                @paginate="paginationClick"
              />
            </nav>
          </div>
        </template>
      </RenderResources>
    </section>
  </div>
</template>

<script lang="ts">
import Pagination from "v-pagination-3";
import ResultsIndicator from "./components/ResultsIndicator.vue";
import Result from "./components/Result.vue";
import {
  AzureSearchResponse,
  GenericSearchResponseFacetsCreatedItem,
  TaxonomyTag,
} from "search-api-client";
import RenderResources from "./components/renderless/RenderResources.vue";
import getSearch from "./services/searchClientRepository";

import SessionStorageService from "./services/sessionStorage.js";
import recentTerms from "./components/RecentlySearched.vue";

import { computed, defineComponent, onMounted, ref } from "vue";

const MIN_KEYWORD_LENGTH_FOR_SEARCH = 3;

export default defineComponent({
  components: {
    ResultsIndicator,
    Result,
    Pagination,
    RenderResources,
    recentTerms,
  },
  props: {
    showkeywords: {
      type: Boolean,
      required: true,
      default: true,
    },
    filtergroupids: {
      type: String,
      required: true,
      default: "",
    },
    filteryear: {
      type: Boolean,
      required: true,
      default: true,
    },
    parentid: {
      required: true,
      default: -1,
    },
    pagesize: {
      required: true,
      default: 20,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    ocpApimSubscriptionKey: {
      type: String,
      required: false,
      default: "",
    },
    indexName: {
      type: String,
      required: true,
      default: "",
    },
    baseUri: {
      required: true,
      default: "",
    },
  },
  setup(props) {
    const keyword = ref("" as string); // This is the variable that tracks user input to the search field
    const searchTerm = ref(""); // This is the value actually searched for
    const currentPage = ref(1);
    const sortOptions = ["A-Z", "Z-A"];
    const sortOption = "A-Z" as string;
    const selectedTags = [];
    const selectedYears = [];
    const totalPages = ref(0);
    const tags = [] as TaxonomyTag[];
    const datetags = [] as GenericSearchResponseFacetsCreatedItem[];
    const index = props.indexName;
    const apiendpoint = `${props.baseUri}`;
    const suggestedSearchTerms = ref([]);

    const getQueryVariable = (variable) => {
      const query = window.location.search.substring(1);
      const vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return "";
    };

    const lastPage = (total: number): number => {
      return currentPage.value * Number(props.pagesize) > total
        ? total
        : currentPage.value * Number(props.pagesize);
    };

    const calculateTotalPages = (total: number): number => {
      return Math.ceil(total / Number(props.pagesize));
    };

    const paginationClick = (page: number) => {
      const elmnt = document.getElementById("resources");
      if (elmnt) {
        elmnt.scrollIntoView();
      }

      currentPage.value = page;
    };
    const isEmpty = (string1: string) => {
      return string1.length === 0 || !string1.trim();
    };
    const updateSearchTerm = (value: string) => {
      searchTerm.value = value;
      currentPage.value = 1;
      if (!isEmpty(value)) {
        SessionStorageService.AddTermToSessionsStorage(
          "recentTerms",
          searchTerm.value,
        );
      }
    };

    const resourcesChanged = (update: AzureSearchResponse) => {
      const total = update.count !== undefined ? update.count : 0;
      totalPages.value = calculateTotalPages(total);
    };

    const clearSuggestions = () => {
      suggestedSearchTerms.value = [];
    };

    const setSuggestions = (suggestions?: string[]) => {
      suggestedSearchTerms.value = suggestions;
    };

    const firstPage = computed(() => {
      return (currentPage.value - 1) * Number(props.pagesize) + 1;
    });

    const handleKeywordInputChanged = (event: KeyboardEvent) => {
      const element = event.target as HTMLInputElement;
      if (
        !isEmpty(element.value) &&
        element.value.length >= MIN_KEYWORD_LENGTH_FOR_SEARCH &&
        event.code != "Enter"
      ) {
        console.log("base uri:" + props.baseUri);
        const Search = getSearch(props.baseUri as string);
        const options = {
          requestOptions: {
            customHeaders: {
              "ocp-apim-subscription-key":
                props.ocpApimSubscriptionKey as string,
              Accept: "application/json;v=1",
            },
          },
        };
        Search.azureAutocomplete(
          `{"query":"${element.value}", "index":"${index}", "filter":"parents/any(parentId: parentId eq '${props.parentid}')" }`,
          options,
        )
          .then((response) => {
            setSuggestions(response.suggestions);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        clearSuggestions();
      }
    };

    const handleSelectSearchTerm = (value) => {
      clearSuggestions();
      keyword.value = value;
      updateSearchTerm(value);
    };

    const handleSearchClicked = () => {
      clearSuggestions();
      updateSearchTerm(keyword.value);
    };

    const handleClearClicked = () => {
      searchTerm.value = "";
      keyword.value = "";
    };

    onMounted(() => {
      keyword.value = getQueryVariable("query");
      searchTerm.value = getQueryVariable("query");
    });

    return {
      firstPage,
      updateSearchTerm,
      resourcesChanged,
      handleKeywordInputChanged,
      handleSelectSearchTerm,
      handleSearchClicked,
      handleClearClicked,
      isEmpty,
      paginationClick,
      calculateTotalPages,
      lastPage,

      suggestedSearchTerms,
      apiendpoint,
      index,
      datetags,
      tags,
      totalPages,
      selectedYears,
      selectedTags,
      sortOption,
      sortOptions,
      keyword,
      searchTerm,
      currentPage,
    };
  },
});
</script>
