const defaultshowkeywords = true;
const defaultfilteryear = true;
const defaultfiltergroupids = "";
const defaultparentid = -1;
const defaultpagesize = 20;

const indexElement = document.getElementById("sm-site-search");

const showkeywords =
  indexElement?.getAttribute("data-showkeywords") == null
    ? defaultshowkeywords
    : indexElement.getAttribute("data-showkeywords")?.toLowerCase() === "true";
const filteryear =
  indexElement?.getAttribute("data-filteryear") == null
    ? defaultfilteryear
    : indexElement.getAttribute("data-filteryear")?.toLowerCase() === "true";
const filtergroupids =
  indexElement?.getAttribute("data-filtergroupids") ?? defaultfiltergroupids;

let parentid = defaultparentid;
const castparentid = Number(indexElement?.getAttribute("data-parentid"));
if (!isNaN(castparentid) && castparentid !== 0) parentid = castparentid;

let pagesize = defaultpagesize;
const castpagesize = Number(indexElement?.getAttribute("data-pagesize"));
if (!isNaN(castpagesize) && castpagesize !== 0) pagesize = castpagesize;

const title = indexElement?.getAttribute("data-title");
const index = indexElement?.getAttribute("data-index");
const url = indexElement?.getAttribute("data-baseuri");
const key = indexElement?.getAttribute("data-ocpApimSubscriptionKey");

const config = {
  showkeywords: showkeywords,
  filtergroupids: filtergroupids,
  filteryear: filteryear,
  parentid: parentid,
  pagesize: pagesize,
  ocpApimSubscriptionKey: key,
  title: title,
  indexName: index,
  baseUri: url,
};

export default config;
