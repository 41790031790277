<template>
  <div class="searchSuggested" v-if="recentSearchTerms.length > 0">
    <h3 class="h5">Recent searches</h3>
    <ul>
      <li v-for="term in recentSearchTerms.slice(0, 5)" :key="term">
        <button
          v-on:click="UpdateTerm(term)"
          :aria-label="'Search for ' + term"
          class="suggestedTerm"
        >
          {{ term }}
        </button>
        <button
          v-on:click="RemoveTermFromSessionStorage(term)"
          :aria-label="'Remove recent search ' + term"
          class="removeTerm"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="9"
            viewBox="0 0 9 9"
            aria-hidden="true"
            role="presentation"
          >
            <g fill="#EB002E" fill-rule="evenodd">
              <path
                d="M6.28.756c.327 0 .594.264.595.592l.037 9.413c.002.343-.269.626-.612.64-.326.013-.6-.24-.614-.567V1.352c0-.328.265-.594.593-.596z"
                transform="rotate(45 6.925 3.06)"
              />
              <path
                d="M11.46 6.241c-.002.328-.269.593-.597.593L1.39 6.828c-.326 0-.59-.265-.591-.59-.001-.325.261-.59.586-.59H10.87c.326 0 .59.264.59.59v.003z"
                transform="rotate(45 6.925 3.06)"
              />
            </g>
          </svg>
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
import { RemoveTermFromSessionStorage } from "../services/sessionStorage.js";
export default {
  name: "recent-terms",
  data() {
    return {
      recentSearchTerms: [],
    };
  },
  created() {
    this.PageLoad();
  },
  methods: {
    PageLoad() {
      const recentTerms = sessionStorage.getItem("recentTerms");
      if (recentTerms != null) {
        this.recentSearchTerms = recentTerms.split("|").reverse();
      }
    },
    UpdateTerm(newValue) {
      this.$emit("updateSearchTerm", newValue);
    },
    RemoveTermFromSessionStorage(term) {
      const update = RemoveTermFromSessionStorage("recentTerms", term);
      this.recentSearchTerms = update.reverse();
    },
  },
};
</script>
