import { SearchAPI } from "search-api-client";

let api: SearchAPI;

export default (url: string) => {
  if (!api) {
    api = new SearchAPI(url);
  }
  return api;
};
