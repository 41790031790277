<script lang="ts">
import {
  GenericSearchRequestTaxonomyItem,
  AzureSearchResponse,
  GenericSearchRequest,
  AzureSearchOptionalParams,
  SearchAPI,
} from "search-api-client";
import { onMounted, watch, ref } from "vue";
export default {
  props: {
    indexName: {
      type: String,
      required: true,
      default: "",
    },
    endPoint: {
      type: String,
      required: true,
      default: "",
    },
    parentid: {
      type: Number,
      required: true,
      default: -1,
    },
    tags: {
      type: Array as () => GenericSearchRequestTaxonomyItem[],
      required: false,
      default: null,
    },
    years: {
      type: Array as () => number[],
      required: false,
      default: () => [],
    },
    keyword: {
      type: String,
      required: false,
      default: "",
    },
    ocpApimSubscriptionKey: {
      type: String,
      required: false,
      default: "",
    },
    page: {
      type: Number,
      required: false,
      default: 0,
    },
    pagesize: {
      type: Number,
      required: false,
      default: 20,
    },
    sortorder: {
      type: Array as () => string[],
      required: false,
      default: () => [],
    },
    customHeaders: {
      type: Object as () => { [key: string]: string },
      required: false,
      default: () => {},
    },
  },
  setup(props, ctx) {
    const result = ref({} as AzureSearchResponse);
    const loading = ref(false);
    const error = ref(false);

    const fetchResources = async (
      parentid = -1,
      keyword = "",
      tags: GenericSearchRequestTaxonomyItem[] = [],
      years: number[] = [],
      page = 1,
      pagesize = 20,
      sortorder: string[] = [],
    ) => {
      loading.value = true;
      error.value = false;
      try {
        if (page < 1) {
          page = 1;
        }
        const skip = (page - 1) * pagesize;
        const genericSearchRequest: GenericSearchRequest = {
          index: props.indexName,
          query: keyword,
          parents: [parentid.toString()],
          skip: skip,
          take: pagesize,
          sort: sortorder,
          yearsCreated: years,
          taxonomy: tags,
        };
        const SearchRepository = new SearchAPI(props.endPoint);
        const options: AzureSearchOptionalParams = {
          ocpApimSubscriptionKey: props.ocpApimSubscriptionKey,
          requestOptions: {
            customHeaders: props.customHeaders,
          },
        };
        result.value = await SearchRepository.azureSearch(
          JSON.stringify(genericSearchRequest),
          options,
        );
        loading.value = false;
        ctx.emit("change", result.value);
      } catch (e) {
        console.log(e);
        error.value = true;
      }
    };

    onMounted(() => {
      fetchResources(
        props.parentid,
        props.keyword,
        props.tags,
        props.years,
        props.page,
        props.pagesize,
        props.sortorder,
      );
    });

    watch(
      () => props.tags,
      (newVal: GenericSearchRequestTaxonomyItem[]) => {
        fetchResources(
          props.parentid,
          props.keyword,
          newVal,
          props.years,
          props.page,
          props.pagesize,
          props.sortorder,
        );
      },
    );
    watch(
      () => props.years,
      (newVal) => {
        fetchResources(
          props.parentid,
          props.keyword,
          props.tags,
          newVal,
          props.page,
          props.pagesize,
          props.sortorder,
        );
      },
    );
    watch(
      () => props.keyword,
      (newVal) => {
        fetchResources(
          props.parentid,
          newVal,
          props.tags,
          props.years,
          props.page,
          props.pagesize,
          props.sortorder,
        );
      },
    );
    watch(
      () => props.page,
      (newVal) => {
        fetchResources(
          props.parentid,
          props.keyword,
          props.tags,
          props.years,
          newVal,
          props.pagesize,
          props.sortorder,
        );
      },
    );
    watch(
      () => props.sortorder,
      (newVal) => {
        fetchResources(
          props.parentid,
          props.keyword,
          props.tags,
          props.years,
          props.page,
          props.pagesize,
          newVal,
        );
      },
    );
    return () =>
      ctx.slots.testSlot({
        error: error.value,
        result: result.value,
        loading: loading.value,
      });
  },
};
</script>
